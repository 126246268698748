import React from "react";

import ContactComponent from "../components/contact/contact";
import Layout from "../components/layout";

export default function Contact() {
  return (
    <div className="contact-container mt-6 pt-6">
      <Layout>
        <ContactComponent />
      </Layout>
    </div>
  );
}
