import React from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "gatsby-plugin-intl";

interface Props {
  subjects: string[];
}

interface FormValueState {
  subject: string;
  name: string;
  email: string;
  message: string;
}

interface FormValidationState {
  /** Match variable name in FormValueState so that we can access both objects using the same prop names */
  subject: boolean;
  /** Match variable name in FormValueState so that we can access both objects using the same prop names */
  name: boolean;
  /** Match variable name in FormValueState so that we can access both objects using the same prop names */
  email: boolean;
  /** Match variable name in FormValueState so that we can access both objects using the same prop names */
  message: boolean;
  emailInvalidFormat: boolean;
  formError: boolean;
  messageSent: boolean;
  loading: boolean;
}
const initFormValues: FormValueState = {
  subject: "",
  name: "",
  email: "",
  message: ""
};

const initFormValidations: FormValidationState = {
  subject: false,
  name: false,
  email: false,
  emailInvalidFormat: false,
  message: false,
  formError: false,
  messageSent: false,
  loading: false
};
const url =
  "https://prod-03.westeurope.logic.azure.com:443/workflows/85420fa9e2754346b04a139080936ce5/triggers/request/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Frequest%2Frun&sv=1.0&sig=CQIjUwBTd7W0FjUrAQwf0TlAycs-jJ_6KBovkePlfPw";

export default function Form({ subjects }: Props) {
  const [values, setValues] = React.useState<FormValueState>({
    subject: "",
    name: "",
    email: "",
    message: ""
  });
  const [validations, setValidations] = React.useState<FormValidationState>({
    subject: false,
    name: false,
    email: false,
    emailInvalidFormat: false,
    message: false,
    formError: false,
    messageSent: false,
    loading: false
  });

  const validateEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const validate = () => {
    let validationsTemp = { ...validations };
    for (const key in values) {
      const typedKey = key as keyof FormValueState;
      const value = values[typedKey];
      if (typedKey === "email") {
        if (value === "") {
          validationsTemp = { ...validationsTemp, [typedKey]: true };
          validationsTemp = { ...validationsTemp, ["emailInvalidFormat"]: false };
        } else if (validateEmail(value)) {
          validationsTemp = { ...validationsTemp, ["emailInvalidFormat"]: true };
          validationsTemp = { ...validationsTemp, [typedKey]: false };
        } else {
          validationsTemp = { ...validationsTemp, [typedKey]: false };
          validationsTemp = { ...validationsTemp, ["emailInvalidFormat"]: false };
        }
      } else if (value === "") {
        validationsTemp = { ...validationsTemp, [typedKey]: true };
      } else {
        validationsTemp = { ...validationsTemp, [typedKey]: false };
      }
    }
    setValidations(validationsTemp);

    for (const key in validationsTemp) {
      if (validationsTemp[key as keyof FormValidationState]) {
        return false;
      }
    }

    return true;
  };
  const submit = async () => {
    if (!validate()) {
      return;
    }
    try {
      setValidations({ ...initFormValidations, loading: true });
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
      });
      // useSetTimeout is only used during development as temporary replacement to fetch
      // await triggerTimeout(3000);
      setValues(initFormValues);
      setValidations({ ...initFormValidations, messageSent: true });
    } catch (error) {
      setValidations({ ...validations, formError: true });
    }
  };

  const handleClick = () => submit();

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLSelectElement>) => {
    // Only allow form submit by using ctrl + Enter when user is writing in a textarea element
    // "Enter" in textarea is reserved for using break lines.
    if ((event.target as HTMLTextAreaElement).type === "textarea") {
      if (event.key === "Enter" && event.ctrlKey) {
        submit();
        return;
      }
      return;
    }

    if (event.key === "Enter") {
      submit();
    }
  };

  const hideNotification = () => {
    setTimeout(() => {
      setValidations({ ...validations, messageSent: false });
    }, 6000);
  };

  const { subject, name, email, message } = values;
  const { subject: subjectInvalid, name: nameInvalid, email: emailInvalid, emailInvalidFormat: emailFormat, message: messageInvalid, formError, messageSent, loading } = validations;
  return (
    <section>
      <br />
      <br />
      <br />
      <div className="columns is-centered">
        <div className="column is-5">
          <div className="field">
            <label className="label">
              <FormattedMessage id="contact.form.subject.label" />
            </label>
            <div className="control">
              <div className={`select ${subjectInvalid ? "is-danger" : ""}`}>
                <select name="subject" value={subject} onChange={handleChange} onKeyDown={handleOnKeyDown}>
                  {subjects.map((s, index) => (
                    <option key={s} value={index === 0 ? "" : s}>
                      {s}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {subjectInvalid && (
              <p className="help is-danger">
                <FormattedMessage id="contact.form.subject.error" />
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">
              <FormattedMessage id="contact.form.name.label" />
            </label>
            <div className="control">
              <input className={`input ${nameInvalid ? "is-danger" : ""}`} name="name" placeholder="Text input" type="text" value={name} onChange={handleChange} onKeyDown={handleOnKeyDown} />
            </div>
            {nameInvalid && (
              <p className="help is-danger">
                <FormattedMessage id="contact.form.name.error" />
              </p>
            )}
          </div>
          <div className="field">
            <label className="label">
              <FormattedMessage id="contact.form.email.label" />
            </label>
            <div className="control">
              <input
                className={`input ${emailInvalid || emailFormat ? "is-danger" : ""}`}
                name="email"
                placeholder="Email input"
                type="email"
                value={email}
                onChange={handleChange}
                onKeyDown={handleOnKeyDown}
              />
            </div>
            {(emailInvalid || emailFormat) && (
              <p className="help is-danger">{emailFormat ? <FormattedMessage id="contact.form.email.errorInvalidFormat" /> : <FormattedMessage id="contact.form.email.errorMissingEmail" />}</p>
            )}
          </div>
          <div className="field">
            <label className="label">
              <FormattedMessage id="contact.form.message.label" />
            </label>
            <div className="control">
              <textarea className={`textarea ${messageInvalid ? "is-danger" : ""}`} maxLength={1000} name="message" value={message} onChange={handleChange} onKeyDown={handleOnKeyDown} />
            </div>
            {messageInvalid && (
              <p className="help is-danger">
                <FormattedMessage id="contact.form.message.error" />
              </p>
            )}
          </div>
          <div className="field is-grouped">
            <div className="control" onClick={handleClick}>
              <button className={`button ${loading ? "is-loading" : ""}`} disabled={loading} type="button">
                <FormattedMessage id="contact.form.button.label" />
              </button>
              {formError && (
                <p className="help is-danger">
                  <FormattedMessage id="contact.form.formError" />
                </p>
              )}
            </div>
          </div>
          <Fade when={messageSent} onReveal={hideNotification}>
            <div className="notification is-success">
              <button className="delete" disabled={loading} type="button" />
              <FormattedMessage id="contact.form.messageSent" />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}
