import React, { useEffect } from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import GetIntlArrayPrimitive from "../../utils/GetIntlArrayPrimitive";

import Form from "./form";

export default function Contact() {
  const intl = useIntl();
  const subjects = GetIntlArrayPrimitive("contact.subjects", intl.messages);

  useEffect(() => {
    // in case of pressing link in footer
    window.scrollTo(0, 0);
  });

  return (
    <div className="d-content d-content--pad">
      <div className="container mb-6">
        <Fade bottom cascade>
          {/* empty div needed for desired cascade effect */}
          <div>
            <p className="title is-size-1 is-size-2-mobile is-spaced has-text-centered orange-color-text">
              <FormattedMessage id="contact.title" />
            </p>
            <div className="columns is-centered">
              <div className="column is-8">
                <p className="subtitle is-4 has-text-centered">
                  <FormattedMessage id="contact.text" />
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <Form subjects={subjects} />
      </div>
    </div>
  );
}
